import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'

export default function useAdres() {
  // Use toast
  const toast = useToast()
  
  const UlkeTable = ref(null)
  const SehirTable = ref(null)
  const SemtTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: 'Ad', key: 'sAd', sortable: true },
  ]

  const perPageUlke = ref(10)
  const perPageSehir = ref(10)
  const perPageSemt = ref(10)

  const toplamUlke = ref(0)
  const toplamSehir = ref(0)
  const toplamSemt = ref(0)

  const currentPageUlke = ref(1)
  const currentPageSehir = ref(1)
  const currentPageSemt = ref(1)

  const perPageOptions = [10, 25, 50, 100]
  const sortBy = computed({
    get() {
      return store.getters.getAdresLocalFilters.sortBy
    },
    set(value) {
      store.commit('setAdresFilter', { key: 'sortBy', value })
    },
  })
  const isSortDirDesc = computed({
    get() {
      return store.getters.getAdresLocalFilters.sortDesc
    },
    set(value) {
      store.commit('setAdresFilter', { key: 'sortDesc', value })
    },
  })

  const dataMetaUlke = computed(() => {
    const localItemsCount = UlkeTable.value ? UlkeTable.value.localItems.length : 0
    return {
      from: currentPageUlke.value === 1 ? 1 : perPageUlke.value * (currentPageUlke.value - 1) + 1,
      to: perPageUlke.value * (currentPageUlke.value) < localItemsCount ? perPageUlke.value * (currentPageUlke.value) : localItemsCount,
      of: toplamUlke.value,
    }
  })

  const dataMetaSehir = computed(() => {
    const localItemsCount = SehirTable.value ? SehirTable.value.localItems.length : 0
    return {
      from: currentPageSehir.value === 1 ? 1 : perPageSehir.value * (currentPageSehir.value - 1) + 1,
      to: perPageSehir.value * (perPageSehir.value) < localItemsCount ? perPageSehir.value * (currentPageSehir.value) : localItemsCount,
      of: toplamSehir.value,
    }
  })

  const dataMetaSemt = computed(() => {
    const localItemsCount = SemtTable.value ? SemtTable.value.localItems.length : 0
    return {
      from: currentPageSemt.value === 1 ? 1 : perPageSemt.value * (currentPageSemt.value - 1) + 1,
      to: perPageSemt.value * (currentPageSemt.value) < localItemsCount ? perPageSemt.value * (currentPageSemt.value) : localItemsCount,
      of: toplamSemt.value,
    }
  })

  const refetchData = () => {
    UlkeTable.value.refresh()
    SehirTable.value.refresh()
    SemtTable.value.refresh()
  }

  watch([currentPageUlke, currentPageSehir, currentPageSemt, perPageSehir, perPageSemt, perPageUlke], () => {
    refetchData()
  })

  const fetchUlkeler = () => {
    store.dispatch('fetchUlkeler', { })
  }

  const fetchSehirler = sGuid => {
    store.dispatch('fetchSehirler', sGuid)
  }

  const fetchSemtler = sGuid => {
    store.dispatch('fetchSemtler', sGuid)
  }

  const getUlkeler = computed(() => {
    const ulkeList = store.getters.getUlkeList
    toplamUlke.value = ulkeList.length
    return ulkeList
  })
  const getSehirler = computed(() => {
    const sehirList = store.getters.getSehirList
    toplamSehir.value = sehirList.length
    return sehirList
  })
  const getSemtler = computed(() => {
    const semtList = store.getters.getSemtList
    toplamSemt.value = semtList.length
    return semtList
  })

  onMounted(fetchUlkeler)
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchUlkeler,
    fetchSehirler,
    fetchSemtler,
    getUlkeler,
    getSehirler,
    getSemtler,
    tableColumns,

    perPageUlke,
    perPageSehir,
    perPageSemt,

    currentPageUlke,
    currentPageSehir,
    currentPageSemt,
    
    toplamUlke,
    toplamSehir,
    toplamSemt,

    dataMetaUlke,
    dataMetaSehir,
    dataMetaSemt,

    perPageOptions,
    sortBy,
    isSortDirDesc,
    
    UlkeTable,
    SehirTable,
    SemtTable,

  }
}
