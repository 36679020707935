<template>
  <div>
    <b-row align-h="between" align-v="baseline" class="mr-1 ml-1">
      <crud-button :onClickList="fetchUlkeler" :onClickAddNew="addNewAdres" />
       <b-button
        variant="primary"
        @click="openUpload()"
      >
        <feather-icon
          icon="UploadIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ $t('Posta Kodu Aktar') }}</span>
      </b-button>
    </b-row>
    <b-row>
      <b-col>
        <b-card no-body class="mb-0">
          <b-table
            ref="UlkeTable"
            class="position-relative"
            :items="getUlkeler"
            :per-page="perPageUlke"
            :current-page="currentPageUlke"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Hiçbir eşleşen kayıt bulunamadı"
            :sort-desc.sync="isSortDirDesc"
          >
            <template #cell(sAd)="data">
              <b-media vertical-align="center">
                <b-link
                  class="font-weight-bold d-block text-nowrap"
                  @click="fetchSehirler(data.item.sGuid)"
                >
                  {{ data.item.sAd }}
                </b-link>
              </b-media>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{ dataMetaUlke.of }} öğeden {{ dataMetaUlke.from }} - {{ dataMetaUlke.to }} arası gösteriliyor</span>
              </b-col>

              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end">
                <b-pagination
                  v-model="currentPageUlke"
                  :total-rows="toplamUlke"
                  :per-page="perPageUlke"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col>
        <b-card no-body class="mb-0">
          <b-table
            ref="SehirTable"
            class="position-relative"
            :items="getSehirler"
            :per-page="perPageSehir"
            :current-page="currentPageSehir"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Hiçbir eşleşen kayıt bulunamadı"
            :sort-desc.sync="isSortDirDesc"
          >
            <template #cell(sAd)="data">
              <b-media vertical-align="center">
                <b-link
                  class="font-weight-bold d-block text-nowrap"
                  @click="fetchSemtler(data.item.sGuid)"
                >
                  {{ data.item.sAd }}
                </b-link>
              </b-media>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

                <span class="text-muted"
                  >{{ dataMetaSehir.of }} öğeden {{ dataMetaSehir.from }} -
                  {{ dataMetaSehir.to }} arası gösteriliyor</span
                >

              <!-- Pagination -->
         
                <b-pagination
                  v-model="currentPageSehir"
                  :total-rows="toplamSehir"
                  :per-page="perPageSehir"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col>
        <b-card no-body class="mb-0">
          <b-table
            ref="SemtTable"
            class="position-relative"
            :items="getSemtler"
            :per-page="perPageSemt"
            :current-page="currentPageSemt"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Hiçbir eşleşen kayıt bulunamadı"
            :sort-desc.sync="isSortDirDesc"
          />
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="
                  d-flex
                  align-items-center
                  justify-content-center justify-content-sm-start
                "
              >
                <span class="text-muted"
                  >{{ dataMetaSemt.of }} öğeden {{ dataMetaSemt.from }} -
                  {{ dataMetaSemt.to }} arası gösteriliyor</span
                >
              </b-col>

              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="
                  d-flex
                  align-items-center
                  justify-content-center justify-content-sm-end
                "
              >
                <b-pagination
                  v-model="currentPageSemt"
                  :total-rows="toplamSemt"
                  :per-page="perPageSemt"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import useAdres from '@/views/management/tanim-yonetim/adres/useAdres';
import ApiService from '@/common/api.service';
import AlertService from '@/common/alert.service';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  methods: {
    addNewAdres() {
      this.$router.push({ name: 'manage-adres-create' })
    },
    openUpload() {
      const fileInput = document.createElement('input');
      fileInput.accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
      fileInput.type = 'file';

      fileInput.addEventListener('change', event => {
        this.uploadExcelFile(event);
      });

      fileInput.click();
    },
    uploadExcelFile(event) {
      const formData = new FormData();
      formData.append('excelFile', event.target.files[0]);

      ApiService.upload('manage/file/adres/postakodu/save', formData, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: 'CheckCircleIcon',
                variant: 'danger',
              },
            });
            // Sonra yapılacaklar
          } else {
            AlertService.error(this, response.message)
          }
        });
    },
  },
  setup() {
    const {
      fetchUlkeler,
      fetchSehirler,
      fetchSemtler,
      getUlkeler,
      getSehirler,
      getSemtler,
      tableColumns,

      perPageUlke,
      perPageSehir,
      perPageSemt,

      currentPageUlke,
      currentPageSehir,
      currentPageSemt,

      toplamUlke,
      toplamSehir,
      toplamSemt,

      dataMetaUlke,
      dataMetaSehir,
      dataMetaSemt,

      perPageOptions,
      sortBy,
      isSortDirDesc,

      UlkeTable,
      SehirTable,
      SemtTable,

      // UI

    } = useAdres()

    return {
      fetchUlkeler,
      fetchSehirler,
      fetchSemtler,
      getUlkeler,
      getSehirler,
      getSemtler,
      tableColumns,

      perPageUlke,
      perPageSehir,
      perPageSemt,

      currentPageUlke,
      currentPageSehir,
      currentPageSemt,

      toplamUlke,
      toplamSehir,
      toplamSemt,

      dataMetaUlke,
      dataMetaSehir,
      dataMetaSemt,

      perPageOptions,
      sortBy,
      isSortDirDesc,

      UlkeTable,
      SehirTable,
      SemtTable,

      // UI

    }
  },
}
</script>

<style>
</style>
